import { Link } from "react-router-dom";

import { useWindowWidth } from "../../hooks/useWindowWidth";
import { TABLET_WIDTH } from "../../constants/window";

import { ReactComponent as ExploreTeam } from "./../../common/assets/explore-team.svg";
import exploreIcon from "./../../common/assets/explore-icon.png"
import exploreIconMobile from "./../../common/assets/explore-icon-mobile.png"
import "./ExplorePortfolioSection.scss";

export const ExplorePortfolioSection = () => {
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < TABLET_WIDTH;
  return (
    <section className="ExplorePortfolioSection">
      <div className="ExplorePortfolioSection-container">
        <div className="ExplorePortfolioSection-content">
          <ExploreTeam className="ExplorePortfolioSection-icon" />
          <h3 className="ExplorePortfolioSection-title">
            Our Team Comprises The Industry's Best.
          </h3>
          <p className="ExplorePortfolioSection-description">
            Our senior developers have extensive knowledge and experience in
            delivering projects of various sizes and complexities. <br />We specialise
            in Fin-tech, E-Commerce, B2B, and B2C platforms.
          </p>
          {isMobile && (
            <img src={exploreIconMobile} className="ExplorePortfolioSection-contentIconMobile" alt="Explore" />
          )}
          <Link to="/portfolio" className="ExplorePortfolioSection-exploreLink">
            Explore Portfolio
          </Link>
        </div>
        {!isMobile && (
          <img src={exploreIcon} className="ExplorePortfolioSection-contentIcon" alt="Explore" />
        )}
      </div>
    </section>
  );
};
