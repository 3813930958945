import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { Layout } from './pages/Layout/Layout';
import { Home } from './pages/Home/Home';
import { Portfolio } from './pages/Portfolio/Portfolio';
import { AboutUs } from './pages/AboutUs/AboutUs';
import {PrivacyPolicy} from "./pages/PrivacyPolicy/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: <Home />,
        index: true
      },
      {
        path: "/portfolio",
        element: <Portfolio />
      },
      {
        path: "/privicy-policy",
        element: <PrivacyPolicy />
      },
      {
        path: "/about-us",
        element: <AboutUs />
      },
      {
        path: "/*",
        element: <Navigate to={"/"} replace />
      }
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
