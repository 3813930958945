import { Link } from "react-router-dom";

import { useWindowWidth } from "../../hooks/useWindowWidth";
import { TABLET_WIDTH } from "../../constants/window";

import { ReactComponent as Instagram } from "./../../common/assets/instagram.svg";
import { ReactComponent as Linkedin } from "./../../common/assets/linkedin.svg";
import { ReactComponent as Twitter } from "./../../common/assets/twitter.svg";
import { ReactComponent as Facebook} from "./../../common/assets/facebook.svg"

import "./Footer.scss";

export const Footer = () => {
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < TABLET_WIDTH;
  return (
    <footer className="Footer">
      <div className="Footer-container">
        {!isMobile && (
          <div className="Footer-sitemap">
            <p className="Footer-sitemapTitle">Sitemap</p>
            <Link to="/" className="Footer-sitemapLink">
              Home
            </Link>
            <Link to="/portfolio" className="Footer-sitemapLink">
              Portfolio
            </Link>
             <Link to="/privicy-policy" className="Footer-sitemapLink">
              Privacy Policy
            </Link>
            <Link to="/about-us" className="Footer-sitemapLink">
              About us
            </Link>
          </div>
        )}
        <div className="Footer-social">
          <p className="Footer-socialTitle">Follow us</p>
          <div className="Footer-socialLinksCnt">
            <a href="https://www.instagram.com/release_asap/" target="_blank" className="Footer-socialLink">
              <Instagram />
            </a>
            <a href="https://www.facebook.com/people/Release-ASAP/100090314304487/" target="_blank" className="Footer-socialLink">
              <Facebook />
            </a>
            <a href="https://www.linkedin.com/company/release-asap" target="_blank" className="Footer-socialLink">
              <Linkedin />
            </a>
          </div>
        </div>
      </div>
      <div className="Footer-border"></div>
      <div className="Footer-container">
        <div className="Footer-legal">
          <p className="Footer-legalTitle">
            © 2023 ReleaseAsap. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
