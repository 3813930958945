import { ContactButton } from "../ContactButton/ContactButton";

// import { ReactComponent as Rating } from "./../../common/assets/rating.svg";
import "./Main.scss";

export const Main = () => {
  return (
    <div className="Main" id="MainSection">
      <h2 className="Main-title">
        We Create End-to-End <br />
        <span className="Main-titleHighlight">Digital Solutions</span> &
        Products.
      </h2>
      <p className="Main-description">
        Our team specialises in building custom websites, software, and mobile
        apps to help solve your business challenges. We work closely with our
        clients to ensure we deliver high-quality, innovative, and efficient
        solutions. Contact us today to take your digital presence to the next
        level!
      </p>
      <ContactButton />
      {/* <div className="Main-rating">
        <span>4.6/5.0</span>
        <Rating />
        <span>15+ Businesses, 35+ Happy clients.</span>
      </div> */}
    </div>
  );
};
