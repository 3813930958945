import { Link } from "react-router-dom";

import oleksiiFoto from "./../../common/assets/Oleksii.jpg";
import alexanderFoto from "./../../common/assets/Alexandr.jpg";
import "./FoundersSection.scss";

export const FoundersSection = () => {
  return (
    <section className="FoundersSection">
      <p className="FoundersSection-mark">
        <span>{"//"}</span> 01 . OUR FOUNDERS
      </p>
      <h3 className="FoundersSection-title">Work directly with the founders</h3>
      <p className="FoundersSection-description">
        Our company values are focused on quality over quantity. For this, we
        are directly involved in every project to build a solid relationship of
        trust and transparency. If you have any questions or would like us to
        evaluate your project needs, don't hesitate to direct message us.
      </p>
      <div className="FoundersSection-foundersList">
        <div className="FoundersSection-founder">
          <img
            src={oleksiiFoto}
            className="FoundersSection-founderImage"
            alt="Oleksii Shurov"
          />
          <h4 className="FoundersSection-founderName">Oleksii Shurov</h4>
          <p className="FoundersSection-founderTitle">CTO + Co-Founder</p>
        </div>
        <div className="FoundersSection-founder">
          <img
            src={alexanderFoto}
            className="FoundersSection-founderImage"
            alt="Alexander Pashkovskiy"
          />
          <h4 className="FoundersSection-founderName">Alexander Pashkovskiy</h4>
          <p className="FoundersSection-founderTitle">CEO + Co-Founder</p>
        </div>
      </div>
      <div className="FoundersSection-links">
        <Link
          to="/#contact"
          className="FoundersSection-link FoundersSection-link--filled"
        >
          Contact founders
        </Link>
        <Link
          to="/portfolio"
          className="FoundersSection-link FoundersSection-link--outlined"
        >
          See Portfolio
        </Link>
      </div>
    </section>
  );
};
