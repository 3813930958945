import banner from "./../../common/assets/about-us-banner.jpg";
import "./AboutUs.scss";

export const AboutUs = () => {
  return (
    <>
      <section className="AboutUs-main">
        <h2 className="AboutUs-mainTitle">About Us</h2>
        <p className="AboutUs-mainDescription">
          Our team specialises in building custom websites, software, and mobile
          apps to help solve your business challenges. We work closely with our
          clients to ensure we deliver high-quality, innovative, and efficient
          solutions. Contact us today to take your digital presence to the next
          level!
        </p>
      </section>
      <section className="AboutUs-desctiption">
        <div className="AboutUs-conteiner">
          <img src={banner} alt="ReleaseASAP" className="AboutUs-banner" />
          <div className="AboutUs-content">
            <p>
              Release ASAP is web and mobile software development company that
              delivers turn-key solutions to fin-tech and e-commerce business in
              both B2B and B2C models.
            </p>
            <p>
              The team behind us took over 5 years to put together, as
              throughout the our careers we have come across truly rare
              professionals in their respective technical fields. For this
              reason, our greatest asset is strong team of industry
              professionals that have impressive personal portfolios and
              careers.
            </p>
            <h4>Our company and our people have a simple set of values:</h4>
            <ul>
              <li>Create scalable and workable solutions.</li>
              <li>Bring transparency and availability around the clock.</li>
              <li>Always focus on quality over quantity.</li>
              <li>
                Treat every project as if it is our own, and invest into its
                success.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
