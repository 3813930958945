import { ReactComponent as MobileDevelopment } from "./../../common/assets/mobile-development.svg";
import { ReactComponent as FullStack } from "./../../common/assets/full-stack.svg";
import { ReactComponent as UiUx } from "./../../common/assets/ux-ui.svg";
import { ReactComponent as QualityAssurance } from "./../../common/assets/quality-assurance.svg";
import { ReactComponent as PureBackend } from "./../../common/assets/pure-backend.svg";
import { ReactComponent as Aws } from "./../../common/assets/aws.svg";
import "./ValuesSection.scss";

export const ValuesSection = () => {
  return (
    <section className="ValuesSection">
      <div className="ValuesSection-content">
        <p className="ValuesSection-mark">
          <span>{"//"}</span> 02 . OUR SERVICES
        </p>
        <h3 className="ValuesSection-title">Our technology stack</h3>
        <div className="ValuesSection-valuesList">
          <div className="ValuesSection-value">
            <span className="ValuesSection-valueIcon ValuesSection-valueIcon--purple">
              <MobileDevelopment />
            </span>
            <h4 className="ValuesSection-valueTitle">Mobile Development</h4>
            <p className="ValuesSection-valueDescription">
              Developing native applications for both iOS and Android platforms.
              Our senior development team can build natively and cross platform.
            </p>
          </div>
          <div className="ValuesSection-value">
            <span className="ValuesSection-valueIcon ValuesSection-valueIcon--yellow">
              <FullStack />
            </span>
            <h4 className="ValuesSection-valueTitle">Full Stack</h4>
            <p className="ValuesSection-valueDescription">
              Our strength is in full-stack development, providing end-to-end
              solutions for web-based applications of any size and complexity.
            </p>
          </div>
          <div className="ValuesSection-value">
            <span className="ValuesSection-valueIcon ValuesSection-valueIcon--green">
              <UiUx />
            </span>
            <h4 className="ValuesSection-valueTitle">UX/UI</h4>
            <p className="ValuesSection-valueDescription">
              We create beautiful, human-centered designs to provide the best
              possible user experience.
            </p>
          </div>
          <div className="ValuesSection-value">
            <span className="ValuesSection-valueIcon ValuesSection-valueIcon--pink">
              <QualityAssurance />
            </span>
            <h4 className="ValuesSection-valueTitle">Quality Assurance</h4>
            <p className="ValuesSection-valueDescription">
              Experienced QA engineering team will build scalable approaches
              using both automated and manual testing.
            </p>
          </div>
          <div className="ValuesSection-value">
            <span className="ValuesSection-valueIcon ValuesSection-valueIcon--blue">
              <PureBackend />
            </span>
            <h4 className="ValuesSection-valueTitle">Pure Backend</h4>
            <p className="ValuesSection-valueDescription">
              We are experts in developing robust and secure backend services
              and architecture for high-load projects.
            </p>
          </div>
          <div className="ValuesSection-value">
            <span className="ValuesSection-valueIcon ValuesSection-valueIcon--orange">
              <Aws />
            </span>
            <h4 className="ValuesSection-valueTitle">Amazon Web Services</h4>
            <p className="ValuesSection-valueDescription">
              Building scalable cloud-based applications using AWS technologies,
              our team has a deep understanding of AWS services, allowing us to
              decouple or upgrade existing AWS infrastructure.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
