import { Link } from "react-router-dom"

import { ReactComponent as Arrow } from "./../../common/assets/arrow.svg";
import "./ContactButton.scss";

export const ContactButton = () => {
  return (
    <Link to="/#contact" className="ContactButton">
      <Arrow />
      <span>Contact The Founders</span>
    </Link>
  );
};
