import { ContactSection } from "../../components/ContactSection/ContactSection";
import { ExplorePortfolioSection } from "../../components/ExplorePortfolioSection/ExplorePortfolioSection";
import { FoundersSection } from "../../components/FoundersSection/FoundersSection";
import { Main } from "../../components/Main/Main";
import { ValuesSection } from "../../components/ValuesSection/ValuesSection";

export const Home = () => {
  return (
    <>
      <Main />
      <FoundersSection />
      <ValuesSection />
      <ExplorePortfolioSection />
      <ContactSection />
    </>
  );
};
