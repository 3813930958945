import { ReactElement, useState } from "react";
import portfolioInsightsFoto from "./../../common/assets/portfolio-insights.png";

import portfolio923 from "./../../common/assets/portfolio-923.jpg"
import portfolioSkyground from "./../../common/assets/portfolio-skyground.jpg"
import portfolioPicamory from "./../../common/assets/portfolio-picamory.jpg"
import { ReactComponent as Close } from "./../../common/assets/close.svg";
import "./Portfolio.scss";

interface IPortfolioCase {
  id: string;
  imageSrc: string;
  title: string;
  descriptionShort: string;
  descriptionFull: ReactElement;
  technologyStack: string[];
}

const portfolio: IPortfolioCase[] = [
  {
    id: "picamory",
    imageSrc: portfolioPicamory,
    title: "Picamory | IOS/Android",
    descriptionShort:
      "A native Android and iOS App that brings users a photo-studio into the palm of their hand.",
    descriptionFull: (
      <>
        <p>
          Picamory is an application that brings a whole photo studio into their
          client's smartphones. Clients can create and print photo books and
          photo collages by choosing and editing their favorites pictures from
          their photo library.
        </p>
        <p>
          The team reached out to us to take over the application from a
          previous development team as they were faced with multiple issues:
        </p>
        <ol>
          <li>Slow speed of development</li>
          <li>Multiple critical bugs are not being resolved</li>
          <li>Poor application performance</li>
          <li>Lack of communication</li>
        </ol>
        <p>
          The release ASAP team was able to bring a whole different dynamic to
          the project and improve all the standing issues.
        </p>
        <p>
          We started by evaluating the project for bugs and errors, as well as
          collecting the existing feedback. All points were then laid out on a
          development plan with a transparent timeline of execution and a clear
          budget.
        </p>
        <p>
          As the project went into development, we set up weekly update meetings
          for the picamory team while at the same time remaining active and
          available through direct messaging (Slack)
        </p>
      </>
    ),
    technologyStack: [
      "React Native",
      "JavaScript",
      "TypeScript",
      "PHP",
      "MySQL",
      "Shopware",
      "Jenkins",
      "Fastlane",
      "Firebase",
    ],
  },
  {
    id: "923",
    imageSrc: portfolio923,
    title: "923 Digital | Backend Development",
    descriptionShort:
      "One of the US top mobile and web development studios. Famous for developing some ground breaking web, mobile and ai solutions.",
    descriptionFull: (
      <>
        <p>
          923Digital is a market leading development agency in the US, with an
          ample portfolio of projects. From how automation to investment and
          fin-tech products- the digital studio has been involved in many
          industries where they left a positive digital impact.
        </p>
        <p>
          The success of the company largely depends on selecting the right
          partners for growth. For this reason, the team at 923 Digital has
          approached us to help out with some challenging back end development
          for their fin-tech applications.
        </p>
        <p>
          Our backend team tasked with building a significant part of 923
          Digital's fin-tech products. Given that our expertise lies in
          fin-tech, we were the ultimate choice for the partnership.
        </p>
      </>
    ),
    technologyStack: [
      "Java",
      "Spring",
      "MySQL",
      "Jenkins",
      "Financial Third party providers integration",
      "12Data",
    ],
  },
  {
    id: "skyground",
    title: "SkyGround Group | Full Stack Development",
    imageSrc: portfolioSkyground,
    descriptionShort:
      "European fin-tech holding group that operates multiple B2B and B2C online investment and finance platforms.",
    descriptionFull: (
      <>
        <p>
          SkyGround Group is a European-based, fin-tech-focused company that
          provides financial technology and services to multiple in-house and
          3rd party brands. With a rapidly growing business, SkyGround required
          the best developers on the market- to cater to their growing product
          needs.
        </p>
        <p>
          Release ASAP team rapidly assembled a full-stack development team to
          maintain and support SkyGround and help them grow their business
          further. Our developers were responsible for projects in the area of
          authorisation, payments, integrations, trading, refactoring, and much
          more.
        </p>
      </>
    ),
    technologyStack: [
      "TypeScript",
      "JavaScript",
      "Gulp",
      "C#",
      "React",
      "Redux",
      "Docker",
      "AWS",
      "Terraform",
      "Kubernetes",
    ],
  },
];

export const Portfolio = () => {
  const [selectedCase, setSelectedCase] = useState<null | IPortfolioCase>(null);

  return (
    <>
      <section className="Portfolio-main">
        <h2 className="Portfolio-mainTitle">
          Successfully delivered projects.
        </h2>
        <p className="Portfolio-mainDescription">
          Whether it's providing turnkey solutions or seamlessly integrating
          with existing projects to tackle complex challenges, Release ASAP
          consistently strives for excellence in every endeavor.
        </p>
      </section>
      <section className="Portfolio-gallery">
        <div className="Portfolio-galleryContainer">
          {portfolio.map((item) => (
            <div className="Portfolio-case" key={item.id}>
              <div className="Portfolio-caseContent">
                <img
                  src={item.imageSrc}
                  alt="Insights"
                  className="Portfolio-caseFoto"
                />
                <h4 className="Portfolio-caseTitle">{item.title}</h4>
                <p className="Portfolio-caseDescription">
                  {item.descriptionShort}
                </p>
              </div>
              <button
                className="Portfolio-caseDetailsBtn"
                onClick={() => setSelectedCase(item)}
              >
                View More
              </button>
            </div>
          ))}
        </div>
      </section>
      {selectedCase && (
        <div className="Modal-background">
          <div className="Modal">
            <div className="Modal-header">
              <button
                className="Modal-headerBtn"
                onClick={() => setSelectedCase(null)}
              >
                <Close className="Modal-headerIcon" />
              </button>
            </div>
            <div className="Modal-content">
              <h2 className="Modal-title">{selectedCase.title}</h2>
              <p className="Modal-description">
                {selectedCase.descriptionFull}
              </p>
              <h3 className="Modal-stack">Technology Stack</h3>
              <ul>
                {selectedCase.technologyStack.map((technology) => (
                  <li key={technology} className="Modal-stackItem">
                    {technology}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
