import banner from "./../../common/assets/about-us-banner.jpg";
import "./PrivacyPolicy.scss";

export const PrivacyPolicy = () => {
  return (
    <>
      <section className="AboutUs-main">
        <h2 className="AboutUs-mainTitle">Privacy Policy</h2>
      </section>
      <section className="AboutUs-desctiption">
        <div className="AboutUs-conteiner">
          <div className="AboutUs-content">
            <h4>Effective Date: 01/05/2023</h4>
              <p>
                  Welcome to Release ASAP ("Company," "we," "our," or "us"). We are committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, and disclose information through our website www.releaseasap.com (the "Website"). By accessing or using our Website, you consent to the terms of this Privacy Policy.
              </p>
              <h4>Information We Collect</h4>
              <p>
                  1.1 Personal Information: We may collect personal information such as your name, email address, phone number, and other contact details when you voluntarily provide them to us through forms or other means on our Website.
              </p>
              <p>
                  1.2 Log Data: We automatically collect certain information when you visit our Website, including your IP address, browser type, referring/exit pages, and operating system.
              </p>
              <p>
                  1.3 Cookies and Similar Technologies: We may use cookies and similar tracking technologies to collect information about your activities on our Website. This information may include your browsing history, pages visited, and preferences.
              </p>
              <h4>Use of Information</h4>

                  2.1 We may use the information we collect for the following purposes:
                  <p>a) To provide and maintain our services, including customer support. </p>
                  <p>b) To communicate with you and respond to your inquiries.</p>
                      <p>c) To personalise your experience on our Website and improve our services.</p>
                          <p>d) To analyse usage patterns and improve the functionality and performance of our Website.</p>
                              <p>e) To prevent, detect, and address technical issues, fraud, or security breaches.</p>
                                  <p>f) To comply with applicable laws, regulations, or legal obligations.</p>

              <h4>Disclosure of Information</h4>
              3.1 We may disclose your personal information in the following circumstances:
              <p>a) To trusted third-party service providers who assist us in operating our Website and providing our services.
              </p>
              <p>b) To comply with legal obligations, such as responding to subpoenas, court orders, or legal processes.
              </p>
              <p>c) To protect our rights, property, or safety, as well as the rights, property, or safety of others.
              </p>
              <p>d) With your consent or at your direction.
              </p>
              <h4>Third-Party Links</h4>
              <p>
                  4.1 Our Website may contain links to third-party websites or services that are not owned or controlled by us. This Privacy Policy does not apply to those third-party websites or services. We recommend reviewing the privacy policies of those third parties before providing any personal information.
              </p>
              <h4>Security</h4>
              <p>5.1 We employ reasonable security measures to protect your personal information from unauthorised access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
              <h4>Children's Privacy</h4>
              <p>6.1 Our Website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will promptly delete such information from our systems.
                  Changes to the Privacy Policy</p>
              <p>7.1 We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on our Website. Your continued use of our Website following the posting of changes constitutes your acceptance of such changes.</p>
              <h4>Contact Us</h4>
              <p>8.1 If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at [insert contact information].
                  Please note that this Privacy Policy does not create any contractual or legal rights between Release ASAP and its users. It is provided for informational purposes only.
              </p>
          </div>
        </div>
      </section>
    </>
  );
};
