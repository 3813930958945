import clsx from "clsx";
import { NavLink, Link } from "react-router-dom";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

import { ContactButton } from "../ContactButton/ContactButton";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import "./Header.scss";
import { ReactComponent as LogoDescktop } from "./../../common/assets/logo.svg";
import { ReactComponent as LogoMobile } from "./../../common/assets/logo-mobile.svg";
import { ReactComponent as MenuIconClosed } from "./../../common/assets/menu-icon.svg";
import { ReactComponent as MenuIconOpen } from "./../../common/assets/menu-icon-open.svg";
import { TABLET_WIDTH } from "../../constants/window";

export const Header = () => {
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth < TABLET_WIDTH;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isDark, setIsDark] = useState(false);
  const headerEl = useRef<HTMLElement>(null);

  const scrollHandle = useCallback(() => {
    const el = document.getElementById("MainSection");
    if (el && headerEl.current) {
      if (
        el.getBoundingClientRect().bottom <
        headerEl.current.getBoundingClientRect().height
      ) {
        setIsDark(true);
      } else {
        setIsDark(false);
      }
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("scroll", scrollHandle);
  }, [scrollHandle]);

  return (
    <header
      ref={headerEl}
      className={clsx("Header", isDark ? "Header--dark" : "Header--light")}
    >
      <div className="Header-content">
        <Link to={"/"}>
          {isMobile ? <LogoMobile /> : <LogoDescktop className="Header-logo" />}
        </Link>

        {!isMobile && (
          <nav className="Header-navigation">
            <NavLink to={"/"} className="Header-navigationLink">
              Home
            </NavLink>
            <NavLink to={"/portfolio"} className="Header-navigationLink">
              Portfolio
            </NavLink>
            <NavLink to={"/about-us"} className="Header-navigationLink">
              About us
            </NavLink>
          </nav>
        )}
        <div className="Header-actions">
          <ContactButton />
          {isMobile && (
            <button
              className="Header-menuButton"
              onClick={() => setIsMenuOpen((open) => !open)}
            >
              {isMenuOpen ? <MenuIconOpen /> : <MenuIconClosed />}
            </button>
          )}
        </div>
      </div>
      {isMenuOpen && (
        <div className="Header-menu">
          <nav className="Header-menuNavigation">
            <NavLink
              to={"/"}
              className="Header-menuNavigationLink"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to={"/portfolio"}
              className="Header-menuNavigationLink"
              onClick={() => setIsMenuOpen(false)}
            >
              Portfolio
            </NavLink>
            <NavLink
              to={"/about-us"}
              className="Header-menuNavigationLink"
              onClick={() => setIsMenuOpen(false)}
            >
              About us
            </NavLink>
          </nav>
        </div>
      )}
    </header>
  );
};
