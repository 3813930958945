import { useForm, SubmitHandler } from "react-hook-form";
import { send } from "@emailjs/browser";

import { ReactComponent as Contact } from "./../../common/assets/contact.svg";
import "./ContactSection.scss";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

type Inputs = {
  email: string;
  company: string;
  subject: string;
  message: string;
};

enum FormState {
  "Success",
  "Fail",
  "None",
}

export const ContactSection = () => {
  const { state } = useLocation();
  const sectionEl = useRef<HTMLElement>(null);
  const [formState, setFormState] = useState(FormState.None);

  useEffect(() => {
    if (state?.contact && sectionEl.current) {
      window.scrollTo({
        behavior: "smooth",
        top: sectionEl.current.offsetTop,
      });
    }
  }, [state?.contact]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setFormState(FormState.None)
    try {
      await send(
        "service_bwzm8rf",
        "template_2pc67oi",
        data,
        "8z8DQCUNRGJY9aBeb"
      );
      reset();
      setFormState(FormState.Success);
    } catch (err) {
      setFormState(FormState.Fail);
    }
  };

  return (
    <section ref={sectionEl} className="ContactSection" id="contact">
      <div className="ContactSection-container">
        <div className="ContactSection-content">
          <Contact className="ContactSection-contentIcon" />
          <h3 className="ContactSection-title">Let's discuss your project.</h3>
          <p className="ContactSection-description">
            Hey, it's Alex and Oleksii.
            <br />
            Contact us to discuss how we can help you achieve your business
            needs.
          </p>
        </div>
        <div className="ContactSection-formContainer">
          {formState === FormState.Success && (
            <p className="ContactSection-formState ContactSection-formState--success">Thank you for reaching out!</p>
          )}
          {formState === FormState.Fail && (
            <p className="ContactSection-formState ContactSection-formState--fail">Something went wrong</p>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="ContactSection-form"
          >
            <label htmlFor="email" className="ContactSection-formLabel">
              Your email *
            </label>
            <input
              id="email"
              {...register("email", {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
              placeholder="example@yourmail.com"
              className="ContactSection-formInput"
            />
            {errors.email && (
              <span className="ContactSection-formError">
                {errors.email.type === "pattern"
                  ? "Invalid email"
                  : "Email is required"}
              </span>
            )}
            <label htmlFor="company" className="ContactSection-formLabel">
              Company *
            </label>
            <input
              id="company"
              {...register("company", { required: true })}
              placeholder="your company name"
              className="ContactSection-formInput"
            />
            {errors.company && (
              <span className="ContactSection-formError">
                Company is required
              </span>
            )}
            <label htmlFor="subject" className="ContactSection-formLabel">
              Subject
            </label>
            <input
              id="subject"
              {...register("subject", { required: false })}
              placeholder="How can we Help"
              className="ContactSection-formInput"
            />
            <label htmlFor="message" className="ContactSection-formLabel">
              Message *
            </label>
            <textarea
              id="message"
              {...register("message", { required: true })}
              placeholder="Descriptions of your project"
              className="ContactSection-formInput"
              rows={3}
            />
            {errors.message && (
              <span className="ContactSection-formError">
                Message is required
              </span>
            )}
            <input
              type="submit"
              className="ContactSection-formSubmit"
              value="Send Message"
            />
          </form>
        </div>
      </div>
    </section>
  );
};
