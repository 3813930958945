import { useEffect, useState } from "react";

export function useWindowWidth() {
  const [width, setWidth] = useState(window.screen.width);

  useEffect(() => {
    const callback = () => {
      setWidth(window.screen.width);
    };
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  return width;
}
